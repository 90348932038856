nav.navbar, .navbar-default {
    background-color: #1D58FF !important;/* #333A56 !important;*/

  }
  
  .navbar-default {
    background-image: none;
    border: none;
    border-radius: 0;
  }
  
  .navbar-default .navbar-nav > li > a {
    color: white;
  }
  
  .navbar-default .navbar-brand {
    color: white;
  }
  
  .navbar-default .navbar-toggle {
    border-color: white;
  }
  
  .navbar-default .navbar-toggle .icon-bar {
    background-color: white;
  }
  
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }
  
  .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
    color: white;
    background-color: transparent;
  }
  
  .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: white;
    background-color: transparent;
  }

  .hyperlink_custom{
    padding-inline: 35px !important;
    color: #FFFFFF !important;
    font-family: 'Rubik-SemiBold';
    font-size: 12pt;

  }

  .nav_menu{
    padding-top: 2em;
  }

  .fondo_menu{
    background-image: url(./../image/fondoMenu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }