.body{
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 992px) {

  .container_footer {
    max-width: 100% !important;
  }
 
  
}

@media (max-width: 1200px){
   .col-sm-3{
    max-width: 0;
    flex: 0;
   }
   .cols_format{
    flex: none;
    max-width: none;
   }
   .card_valores{
    width: 20rem;
    margin-bottom: 10rem;
   }
   .dos_columnas{
    display: grid;
    flex-wrap: wrap;
    margin: 0;
   }
   .jumbotron_custom_valores{
    columns: 1;
   }
   .valores{
    width: 21.5rem;
    margin-left: 0rem;
    margin-top: -9em;
   }
   .card_nosotros_mision{
    width: 24rem;
    margin-bottom: 3em;
    margin-left: auto;
   }
   .card_nosotros_vision{
    width: 24rem;
    margin-bottom: 3em;
    margin-left: -1em !important;
   }
   .nosotros_titulo{
    margin-bottom: -4em;
   }
   .cuadro_mujer{
    border: none !important;
   }
   .mujer_estilo{
    border: solid 7px #1D58FF !important;
    border-radius: 20px !important; 
    max-width: 50% !important;
    margin-left: 0rem;
   }
   .jumbotron_custom_nosotros{
    background-position: 25% 50% !important; 
   }
   .servicios_row{
    columns: 1;
   }
   .col_servicios{
    margin-left: 0em !important;
    margin-right: 0em;
   }
   .comas{
    margin-top: -2.1rem !important;
   }
   .hombre_estilo{
    margin-top: 5em !important;
    margin-left: 0rem;
    max-width: 100% !important;
   }
   .card_servicios_areas{
    background-size: contain !important;
    height: 300px !important;
    margin-top: -1.5rem !important;
    background-position: 150% 5%;
   }
   .card_text_areas{
    width: 60% !important;
    font-size: small !important;
    line-height: 13px !important;
    padding-top: 0.0rem !important;
   }
   .car_title_lab{
    height: 6.5rem;
    padding-top: 32px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
   }
   .car_title_lab2{
    height: 6.5rem;
    padding-top: 32px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
   }
   .card_text_lab2{
    margin-right: -4em !important;
    margin-left: -3em !important;
   }
   .car_title_lab3{
    height: 6.5rem;
    padding-top: 32px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
   }
   .card_text_lab3{
    margin-right: -4em !important;
    margin-left: -3em !important;
   }
   .card_text_lab4{
    margin-right: 0 !important;
    margin-left: -1em !important;
   }
   .car_title_lab4{
    height: 6.5rem;
    padding-top: 32px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
   }
   .footer3{
    width: 90% !important;
    margin: auto !important;
   }
   .footer2{
    width: 90% !important;
    margin: auto !important;
   }
   .footer1{
    width: 90% !important;
    margin-left: auto !important;
   }
   .copyright-text{
    white-space: normal !important;
    margin: auto !important;
   }
   .row_servicios{
    margin-left: 0 !important;
    width: 100% !important;
   }
   .llamar_ahora{
    width: 70% !important;
    padding-top: 10% !important;

   }
   .card_servicios{
    height: 18em !important;
   }
   .hombre_img{
    margin-left: -12rem !important;
   }
   .rectangulo_img{
    margin-top: -3rem !important;
   }
   .footer_row{
    display: contents !important;
   }
}
.footer_row{
  display: flex;
  margin-top: 3%;
}
.footer3{
  width: 20%;
  margin: auto;
}
.footer2{
  width: 60%;
  margin: auto;
}
.footer1{
  width: 20%;
  margin-left: auto;
}
#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 4.5rem;    /* Footer height */
  background-color: #FFFFFF;
  /*background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
}

#footer {
  position: inherit;
  bottom: 0;
  width: 100%;
}

.back_color{
  background: #1D58FF;
}

.footer_top_space{
  margin-top: 10px;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.logo_empresa{
  /*background-image: url(./../image/logo_empresa.png);
  padding: 45px 125px 14px 25px;
  background-repeat: no-repeat;*/
  width: 150px;
  height: 50px;
}
 

.site-footer
{
  background-color: #1D58FF;/*#272e65;*/
  /*padding:45px 0 20px;*/
  font-size:15px;
  line-height:24px;
  color:#737373;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.footer_font{
  color:#bfbfbf !important;
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#FFFFFF;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#1D58FF
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
/*.site-footer .social-icons
{
  text-align:right
}*/

.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#1D58FF
}

.social_footer{
  flex-grow: 0;
  padding-top: 2.75rem;

}

.copyright-text
{
  font-family: Quicksand;
  font-size: 9.5pt;
  color: #FFFFFF;
  white-space: nowrap;
  margin-left: 0;
}
.copyright-text-bold{
  font-family: Quicksand-Bold;
  font-size: 9.5pt;
  color: #FFFFFF;
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.mail:hover
{
  background-color:#db4437
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}

.social-icons a.instagram:hover{
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)
}

@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}


/****************HOME**********************/

.init_size{
  min-height: 230px;
}

.dot_Home{
    text-align: center;
    height: auto;
    width: auto;
    background-color: transparent;
    /*border-radius: 50%;*/
    display: inline-block;
    color:white;
}

.icons_Home{
  font-size: 35px;
  margin-top: -100px;
}

.cols_format{
  text-align: center;
  
}

.container_home{
  margin-top: 0px;
  margin-bottom: 50px;
}

.jumbotron_custom_main{
  background-image: url(./../image/fondoTotal.png);
  background-color: #1D58FF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: max-content;
  /*background-size: auto 100%;*/
}

.jumbotron_custom_servicios{
  background: url(./../image/fondoDerExa.png) right no-repeat, url(./../image/fondoIzqExa.png) left no-repeat;
  background-color: #FFFFFF;
  background-position: -20px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  height: max-content;
  margin-top: 8em;
}
.fondo_productos{
  background-image: url(./../image/fondoProductos.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position-x: center;
  margin-top: 15em;

}
.container_servicios{
  background-image: url(./../image/fondoContainerProd.png);
  background-color: #FFFFFF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: max-content;
}
.fondo_col_servicios{
  background-image: url(./../image/fondoColsProd.png);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px !important;
  height: 140px;
  margin-top: -5em;
  background-size: cover;
  
}
.col_servicios{
  border-radius: 20px;
  margin-left: 30em;
}
.row_servicios{
  width: 80%;
  margin-left: 110px;
}
.llamar_ahora{
  width: 40%;
  padding-top: 1.5rem;
}

.jumbotron_custom_main_Construction_site{
  background-color: #e8e8e8;
  background-image: url(./../image/banner.jpg);
  color: #f7f7f7;
  margin-left: -15px;
  margin-right: -15px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.jumbotron_custom{
  background-size: 100% 100%;
  background-color: #e8e8e8;
  background-image: url(./../image/banner.jpg);
  color: #f7f7f7;
  margin-left: -15px;
  margin-right: -15px;
  background-repeat: no-repeat;
}

.micro_imag{
  background-image: url(./../image/micro.png);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  /*height: 700px;*/
}

@media (min-width: 576px){
  .jumbotron {
      padding: 3.8rem 2rem !important;
  }
}

.container_info{
  background-color: #e8e8e8;
  background-image: url(./../image/bloque.jpg);
  color: #f7f7f7;
  padding: 45px 25px 45px 25px;
}

.container_info2{
  background-color: #e8e8e8;
  background-image: url(./../image/bloque2.jpg);
  color: #f7f7f7;
  padding: 45px 25px 45px 25px;
  margin-top: 2px;
}

.information_container{
  margin-top: 50px;
}

.vals_information{
  text-align:justify !important;
}

.ocultospocisionamiento{
  text-align:justify !important;
}

.texto_home{
  font-family: 'Quicksand';
  font-size: 12pt;
  color: #FFFFFF;
  line-height: 15pt;
  border-left: solid #FFFFFF;
}

.separar{
  margin-bottom: 5rem;
}

.valores{
  background-color: #CC2B00;
  border: 8px solid #CC2B00;
  border-radius: 2em;
  height: 1px;
  /*width: 97%;*/
  
}
.jumbotron_custom_valores{
  background-color: #FFFFFF;
}
.jumbotron_custom_nosotros{
  background-image: url(./../image/fondoNosotros.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 52%;
  background-color: #FFFFFF;
  margin-top: -5rem;
}
.card_valores{
  background-color: #F9F3EA;
  border: none;
  border-radius: 20px;
  
}
.card_titulos_valores{
  font-family: Rubik-SemiBold;
  font-size: 16pt;
  color: #6B6B6B;
  text-transform: uppercase;
}
.card_texto_valores{
  font-family: Quicksand;
  font-size: 10pt;
  color: #6B6B6B;
  line-height: 15pt;
  height: 300px;
}
.card_pies{
  border: none;
  background-color: #F9F3EA;
  
}
.card_servicios_areas{
  width: 86%;
  background-image: url(./../image/rectServicios.png);/*#1D58FF;*/
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-top: -5em;
  /*padding: 3em;*/
  height: 300px;
  border: none;
}
.card_text_areas{
  font-size: 25pt;
  color: #F9F3EA;
  line-height: 25pt;
  width: 60%;
  padding-top: 5%;

}
.car_title_lab{
  font-size: 25pt;
  color: #F9F3EA;
  background-color: #1D58FF;
  border-radius: 20px;
  padding-left: 3rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  margin-top: -2em;
  margin-left: -3em;
  margin-right: 2em;
  text-align: initial;
}
.car_title_lab2{
  font-size: 25pt;
  color: #F9F3EA;
  background-color: #1D58FF;
  border-radius: 20px;
  padding-left: 3rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  margin-top: -2em;
  margin-left: -6.1em;
  margin-right: 1.1em;
  text-align: initial;
}
.car_title_lab3{
  font-size: 25pt;
  color: #F9F3EA;
  background-color: #1D58FF;
  border-radius: 20px;
  padding-left: 3rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
  margin-top: -2em;
  margin-left: -6.1em;
  margin-right: 1.1em;
  text-align: initial;
}
.car_title_lab4{
  font-size: 25pt;
  color: #F9F3EA;
  background-color: #1D58FF;
  border-radius: 20px;
  padding-left: 3rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
  margin-top: -2em;
  margin-left: -6.1em;
  margin-right: 1.1em;
  text-align: initial;
}
.card_text_lab{
  text-align: initial;
  background-color: #F9F3EA;
  margin-right: -6em;
  border-radius: 20px;
}
.card_text_lab2{
  text-align: initial;
  background-color: #F9F3EA;
  width: 39rem;
  margin-right: -10em;
  margin-left: -2em;
  border-radius: 20px;
}
.card_text_lab3{
  text-align: initial;
  background-color: #F9F3EA;
  width: 39rem;
  margin-right: -10em;
  margin-left: -2em;
  border-radius: 20px;
}
.card_text_lab4{
  text-align: initial;
  background-color: #F9F3EA;
  width: 59rem;
  margin-right: -3em;
  border-radius: 20px;
}
.card_lab{
  background-color: #F9F3EA;
  border-radius: 20px;
  border: none;
}
.card_lab2{
  background-color: #F9F3EA;
  border-radius: 20px;
  border: none;
}
.card_lab3{
  background-color: #F9F3EA;
  border-radius: 20px;
  border: none;
}
.card_lab4{
  background-color: #F9F3EA;
  border-radius: 20px;
  border: none;
}
.rubik_bold{
  font-family: Rubik-Bold;
}
.rubik_semi{
  font-family: Rubik-SemiBold;
}
.rubik_regular{
  font-family: Rubik;
}
.mision_titulo{
  font-size: 18pt;
  color: #F9F3EA;
  text-transform: uppercase;
}
.qk_sam{
  font-family: Quicksand;
}
.vision_texto{
  font-size: 10pt;
  color: #6B6B6B;
}
.mision_texto{
  font-size: 10pt;
  color: #F9F3EA;
  margin-bottom: 50px;
}
.vision_titulos{
  font-size: 18pt;
  color:#6B6B6B;
  text-transform: uppercase;
}
.nosotros_titulo{
  font-size: 36pt;
  color: #6B6B6B;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.lista_servicios li{
  list-style: none outside none;
}
.lista_servicios li::before{
  content: ">";
  display: inline-block;
  margin-left: -40px;
  letter-spacing: 10pt;
}
.lista_servicios_puntos{
  columns: 2;
  font-size: 16pt;
  color: #6B6B6B;
}
/*.lista_servicios_puntos li::before{
  content: "•";
  display: inline-block;
  letter-spacing: 10pt;
}*/
.lista_servicios_puntos_columna{
  font-size: 16pt;
  color: #6B6B6B;
  text-align: initial;
}
/*.lista_servicios_puntos_columna li::before{
  content: "•";
  display: inline-block;
  letter-spacing: 10pt;
}*/
.servicios_titulo{
  width: 8em;
  margin-top: 4em;
  line-height: 60px;
  margin-left: 5px;
  margin-bottom: 1.5em;
}

.nosotros_row{
  margin-top: 10rem;
}
.nosotros_cols{
  max-width: 30%;
  
}
.servicios_row{
  padding-left: 5em;
  padding-right: 3em;
}
.servicios_row_text{
  margin-top: 10em;
}
.servicios_row_text_final{
  margin-bottom: -10em;
  padding-bottom: 14rem;
}
.card_nosotros_mision{
  background-color: #1D58FF;
  border-radius: 20px;
  margin-right: -75px;
  margin-top: 5em;
  z-index: 3;
}
.card_nosotros_vision{
  background-color: #F9F3EA;
  border-radius: 20px;
  margin-left: -125px;
  margin-top: 5em;
  border: none;
  padding-left: 10px;
  position: inherit;
  z-index: 1;

}
.card_servicios{
  background-color: #F9F3EA;
  border-radius: 20px;
  margin-left: -95px;
  margin-top: -1.5em;
  height: 10em;
  border: none;
}
.cols_servicios{
  max-width: 40%;
}
.card_text_servicios{
  font-size: 16pt;
  color:#6B6B6B;
  line-height: 20pt;
}
.comas{
  margin-top: -1.1em;
}
.card_pies_nosotros{
  border: none;
  background-color: #1D58FF;
}
.mujer_estilo{
  
   /*background-image: url(../image/recuadroMujer.png);
  background-size: cover;
  background-repeat: no-repeat;*/
  z-index: 2;
}
.hombre_estilo{
  border: solid 7px #1D58FF;
  border-radius: 20px;
  position: inherit;
  background-position: center;
  max-width: 49%;
  margin-right: 25em;
  margin-top: -8em;
   /*background-image: url(../image/recuadroMujer.png);
  background-size: cover;
  background-repeat: no-repeat;*/
}
.mujer_img{
  z-index: 2;
}
.hombre_img{
  margin-left: -4em;
  margin-top: -7.5rem;
}
.rectangulo_img{
  margin-top: -12.8em;
  border: none;
}
.img_prd1{
  margin-top: 10em;
}
.cuadro_mujer{
  border: solid 7px #1D58FF;
  border-radius: 20px;
  z-index: 0;
  margin-left: 20em;
  margin-top: -46rem;
  margin-right: 22em;
  height: 46em;
}
/******************************Contact*********************/

.table_text{
  text-align: left;
}

.contac_table_tittle{
  font-weight: bold;
}

.contac_table_phone{
  text-align: right
}

.blank_contact_table{
  height: 20px;
}

/*****************************SERVICES***********************/

.information_services_container{
  text-align: left;
  padding: 40px;
  background-image: url(../image/fondoTotal.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1D58FF;
  background-position: center;
  width: 1024px;
  
}

/********************************ABOUT***************************/



@media screen and (max-device-width: 480px) and (orientation: portrait){
  /* some CSS here */
  .about_tittle{
    margin-top: -40px;
  }
  
}

.img_valors_size{
  width: 100%;
}

.format_container{
  margin-top:-30px !important
}

.footer_container{
  margin-top: 2px;
}

.list_bullets{
  list-style: none;
  margin-left: -40px;
}



/**************************DIAGRAMA DE VALORES*************************/


/*

a {
   color: inherit;
}

.menu-item,
.menu-open-button {
   background: #005fa4;
   border-radius: 100%;
   width: 80px;
   height: 80px;
   margin-left: -40px;
   position: absolute;
   color: #FFFFFF;
   text-align: center;
   line-height: 80px;
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
   -webkit-transition: -webkit-transform ease-out 200ms;
   transition: -webkit-transform ease-out 200ms;
   transition: transform ease-out 200ms;
   transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open {
   display: none;
}

.lines {
   width: 25px;
   height: 3px;
   background: #596778;
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -12.5px;
   margin-top: -1.5px;
   -webkit-transition: -webkit-transform 200ms;
   transition: -webkit-transform 200ms;
   transition: transform 200ms;
   transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
   -webkit-transform: translate3d(0, -8px, 0);
   transform: translate3d(0, -8px, 0);
}

.line-2 {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

.line-3 {
   -webkit-transform: translate3d(0, 8px, 0);
   transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
   -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
   transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
   -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
   transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
   -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
   transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
   margin: auto;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 80px;
   height: 80px;
   text-align: center;
   box-sizing: border-box;
   font-size: 26px;
}


.menu-item:hover {
  background: #EEEEEE;
  color: #3290B1;
}

.menu-item:nth-child(3) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(4) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(5) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(6) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(7) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(8) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(9) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button:hover {
  -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
  transform: translate3d(0.08361px, -104.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
  transform: translate3d(90.9466px, -52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
  transform: translate3d(90.9466px, 52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 480ms;
  -webkit-transition-duration: 480ms;
  -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
  transform: translate3d(0.08361px, 104.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(7) {
  transition-duration: 580ms;
  -webkit-transition-duration: 580ms;
  -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
  transform: translate3d(-90.86291px, 52.62064px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(8) {
  transition-duration: 680ms;
  -webkit-transition-duration: 680ms;
  -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
  transform: translate3d(-91.03006px, -52.33095px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(9) {
  transition-duration: 780ms;
  -webkit-transition-duration: 780ms;
  -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
  transform: translate3d(-0.25084px, -104.9997px, 0);
}

.blue {
  background-color: #669AE1;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.blue:hover {
  color: #669AE1;
  text-shadow: none;
}

.green {
  background-color: #70CC72;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.green:hover {
  color: #70CC72;
  text-shadow: none;
}

.red {
  background-color: #FE4365;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.red:hover {
  color: #FE4365;
  text-shadow: none;
}

.purple {
  background-color: #C49CDE;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.purple:hover {
  color: #C49CDE;
  text-shadow: none;
}

.orange {
  background-color: #FC913A;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.orange:hover {
  color: #FC913A;
  text-shadow: none;
}

.lightblue {
  background-color: #62C2E4;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.lightblue:hover {
  color: #62C2E4;
  text-shadow: none;
}

.credit {
  margin: 24px 20px 120px 0;
  text-align: right;
  color: #EEEEEE;
}

.credit a {
  padding: 8px 0;
  color: #C49CDE;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.credit a:hover {
  text-decoration: underline;
}

.ubication_flow{
  height: 100px;
    margin-top: 150px;
    text-align: center;
}*/
/*********************************************************************/


/*********************************  ConstructionSite***************************************/

.image_construction{
  height: 60%;
  width: 60%;
}

.row_image_construction{
  text-align: center;
}

.links_custom{
  color:yellow !important;
}