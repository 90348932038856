body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(assets/Fonts/Rubik/Rubik-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik-SemiBold';
  src: local('Rubik-SemiBold'), url(assets/Fonts/Rubik/Rubik-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik-Bold';
  src: local('Rubik-Bold'), url(assets/Fonts/Rubik/Rubik-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(assets/Fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: local('Quicksand-Bold'), url(assets/Fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
}